"use client"

import { cx } from "class-variance-authority"
import Image, { type StaticImageData } from "next/image"
import Link from "next/link"
import { Globe, Mail, Phone } from "react-feather"
import LogoKatalogElektronik from "shared-assets/icons/logo-katalog-elektronik.svg"
import WhatsappIcon from "shared-assets/icons/whatsapp-icon.webp"
import BsreLogo from "shared-assets/images/bsre.webp"
import { Divider } from "shared-ui"

import { govmartLinks, guideLinks, socmedLinks } from "./constant"
import { headerFooterUrls } from "@/utils/common"
import useCheckPathname from "@/utils/useCheckPathname"
import "./style.css"

const Footer = () => {
  const isPdfViewerPage = useCheckPathname(["pdf-viewer"])
  if (isPdfViewerPage) {
    return null
  }

  const currentDate = new Date()
  const currentYear = currentDate.getFullYear()

  return (
    <div className="w-full bg-tertiary25">
      <div className="mx-auto w-full max-w-[1440px] overflow-x-auto bg-tertiary25 text-tertiary500">
        <div className="bg-tertiary25 px-6 pb-2 pt-8">
          <footer className="flex flex-col gap-y-6">
            <div className="mx-auto w-full max-w-[1440px] md:px-4 lg:px-10 xl:px-[118px]">
              <div className="flex w-full items-center justify-between">
                <Image
                  src={LogoKatalogElektronik as StaticImageData}
                  alt="e-katalog-footer-logo"
                  width={160}
                  height={24}
                />
                <div className="flex gap-3 ">
                  <div className="text-lg font-semibold">Ikuti Kami : </div>
                  <div className="flex gap-3">
                    {socmedLinks.map((item, idx) => {
                      return (
                        <div key={`socmed_${idx}`}>
                          <Link href={item.link} target="_blank">
                            <Image
                              src={item.imgUrl}
                              alt={item.name}
                              className={cx(
                                "social-med-icons h-8 w-8 object-cover",
                                item.name
                              )}
                            />
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
              <Divider className="my-6" />
              <div className="flex w-full items-start justify-between gap-10">
                <div className="w-[180px]">
                  <div className="text-xl font-bold">Kebijakan</div>
                  <div className="mt-6 flex flex-col gap-4 text-sm font-normal text-tertiary300">
                    {govmartLinks.map((item, idx) => {
                      return (
                        <div
                          key={`eKatalog_${idx}`}
                          className="font-semibold text-tertiary500"
                        >
                          <Link href={item.link} target="_blank">
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div>
                  <div className="text-xl font-bold">Panduan</div>
                  <div className="mt-6 flex flex-col gap-4 text-sm font-normal text-tertiary300">
                    {guideLinks.map((item, idx) => {
                      return (
                        <div
                          key={`guide_${idx}`}
                          className="font-semibold text-tertiary500"
                        >
                          <Link href={item.link} target="_blank">
                            {item.label}
                          </Link>
                        </div>
                      )
                    })}
                  </div>
                </div>
                <div>
                  <div className="text-xl font-bold">Hubungi Kami</div>
                  <div className="mt-6 flex flex-col gap-4 text-sm font-normal text-tertiary300">
                    <div className="flex flex-col">
                      <div className="flex gap-2 font-semibold text-tertiary500">
                        <Phone size={20} />
                        <span>Call Center 144</span>
                      </div>
                      <div className="mt-2">(Operasional Layanan 24/7)</div>
                    </div>

                    <Link href={headerFooterUrls.whatsapp} target="_blank">
                      <div className="flex flex-col">
                        <div className="flex gap-2 font-semibold text-tertiary500">
                          <Image
                            src={WhatsappIcon}
                            alt="RUP-not-found"
                            width={20}
                            height={20}
                          />
                          <span>WhatsApp LKPP: 08 111 5577 09</span>
                        </div>
                        <div className="mt-2">
                          (Senin - Jumat: 09.00 - 18.00 WIB)
                        </div>
                      </div>
                    </Link>

                    <Link href="mailto:layanan@lkpp.go.id" target="_blank">
                      <div className="flex items-center gap-2 text-tertiary500">
                        <div>
                          <Mail className="mt-1" size={20} />
                        </div>
                        <div className="font-semibold">layanan@lkpp.go.id</div>
                      </div>
                    </Link>

                    <Link href={headerFooterUrls.e_office} target="_blank">
                      <div className="flex gap-2 font-semibold text-tertiary500">
                        <div>
                          <Globe size={20} />
                        </div>
                        <div className="font-semibold">e-Office</div>
                      </div>
                    </Link>
                  </div>
                </div>
                <div>
                  {/* unncomment this if Keamanan dan Privasi comfirmed */}
                  {/* <div className="text-xl font-bold">Keamanan dan Privasi</div>
                <div className="my-6 flex gap-4 text-sm font-normal text-tertiary300">
                  <Image
                    src={`${CDN_URL}/images/footer/footer-pci-dss-compliant.png`}
                    alt="RUP-not-found"
                    width={76}
                    height={52}
                  />
                  <Image
                    src={`${CDN_URL}/images/footer/footer-bsi-iso.png`}
                    alt="RUP-not-found"
                    width={100}
                    height={60}
                  />
                </div> */}
                  <div className="text-xl font-bold">Bekerjasama Dengan</div>
                  <div className="mb-6 mt-3 flex gap-4 text-sm font-normal text-tertiary300">
                    <Image
                      src={BsreLogo}
                      alt="RUP-not-found"
                      width={111}
                      height={54}
                    />
                    {/* <Image
                    src={`${CDN_URL}/logo/privy-logo.png`}
                    alt="RUP-not-found"
                    width={100}
                    height={60}
                  /> */}
                  </div>
                </div>
              </div>
            </div>
            <div className="border-t border-tertiary50 p-4 text-center text-base text-tertiary200">
              &copy; {currentYear} Lembaga Kebijakan Pengadaan Barang/Jasa
              Pemerintah (LKPP)
            </div>
          </footer>
        </div>
      </div>
    </div>
  )
}

export default Footer
