import SocialMediaIcons from "shared-assets/icons/footer-social-media-icons.png"

import { headerFooterUrls } from "@/utils/common"

export const govmartLinks = [
  {
    link: headerFooterUrls.tnc,
    label: "Syarat & Ketentuan",
  },
  {
    link: headerFooterUrls.policy_privacy,
    label: "Kebijakan Privasi",
  },
]

export const guideLinks = [
  {
    link: headerFooterUrls.buyer_guide,
    label: "Panduan Pembeli",
  },
  {
    link: headerFooterUrls.seller_guide,
    label: "Panduan Penyedia",
  },
  {
    link: headerFooterUrls.faq_buyer,
    label: "FAQ Pembeli",
  },
  {
    link: headerFooterUrls.faq_seller,
    label: "FAQ Penyedia",
  },
]

export const socmedLinks = [
  {
    link: "https://www.instagram.com/lkpp_ri/",
    imgUrl: SocialMediaIcons,
    name: "instagram",
  },
  {
    link: "https://www.facebook.com/LKPPRI/?locale=id_ID",
    imgUrl: SocialMediaIcons,
    name: "facebook",
  },
  {
    link: "https://twitter.com/lkpp_ri?lang=en",
    imgUrl: SocialMediaIcons,
    name: "twitter",
  },
  {
    link: "https://www.youtube.com/@lembagakebijakanpengadaanb53",
    imgUrl: SocialMediaIcons,
    name: "youtube",
  },
]
