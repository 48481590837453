"use client"

import { type Session } from "next-auth"
import Image from "next/image"
import Link from "next/link"
import { type MouseEvent } from "react"
import { LogOut, Settings } from "react-feather"

import DropdownMenu from "@/components/dropdown-menu"
import AccountInfo from "./components/AccountInfo"
import NotificationSection from "./components/NotificationSection"

import { signOut } from "@/authentication/authService"
import { getAccountPpnUrl } from "@/utils/common"
import HeaderLogoImage from "./HeaderLogoImage"

interface IHeaderProps {
  session?: Session | null
}

const handleSignout = (e: MouseEvent<HTMLDivElement>) => {
  e.preventDefault()
  signOut()
}

const Header = ({ session }: IHeaderProps) => {
  return (
    <div className="fixed top-[34px] z-[999] h-[76px] w-full bg-primary25">
      <div className="shadow-1">
        <div className="mx-auto flex h-[74px] w-full items-center gap-6 px-10">
          <Link href="/bendahara">
            <HeaderLogoImage width={101.2} height={14.8} />
          </Link>
          <>
            {session?.token?.accessToken && (
              <section className="ml-auto flex items-center gap-4">
                <div className="flex items-center gap-4 border-r border-r-primary50 pr-4">
                  <NotificationSection isBendahara={true} />
                </div>

                {session?.user?.fullName && (
                  <>
                    <DropdownMenu
                      trigger={
                        <>
                          {session?.user?.picture && (
                            <div className="w-10">
                              <Image
                                data-test="header-user-avatar"
                                src={session?.user?.picture}
                                alt="Profile"
                                width={40}
                                height={40}
                                className="rounded-full"
                              />
                            </div>
                          )}

                          <div className="ml-2 flex w-19 flex-col">
                            <h1
                              data-test="header-user-fullname"
                              className="truncate text-xs font-semibold capitalize sm:text-base"
                            >
                              {session.user.fullName || ""}
                            </h1>
                            <p
                              data-test="header-user-role"
                              className="mt-1 text-[10px] capitalize text-tertiary300 sm:text-xs"
                            >
                              {session.user.role}
                            </p>
                          </div>
                        </>
                      }
                    >
                      <div className="w-25 pt-3">
                        <AccountInfo session={session} />

                        <Link
                          href={
                            getAccountPpnUrl(session.user.personaId).setting
                          }
                          target="_blank"
                          data-test="header-profile-link"
                        >
                          <div className="flex cursor-pointer items-center gap-2 px-6 py-3 text-sm font-normal">
                            <Settings size={20} />
                            <p data-test="dropdown-option">Pengaturan</p>
                          </div>
                        </Link>

                        <div
                          className="flex cursor-pointer items-center gap-2 px-6 py-3 text-sm font-normal"
                          onClick={handleSignout}
                        >
                          <LogOut width={20} />
                          Keluar
                        </div>
                      </div>
                    </DropdownMenu>
                  </>
                )}
              </section>
            )}
          </>
        </div>
      </div>
    </div>
  )
}

export default Header
